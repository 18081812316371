import { IsNotEmpty, IsString, IsUUID } from 'class-validator';

export const LOG_ANNOUNCEMENT_CLICK_PATH =
  'announcement/log-announcement-click';

export class LogAnnouncementClickRequest {
  @IsUUID()
  announcementPid: string;

  @IsString()
  @IsNotEmpty()
  target: string;
}
