import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_ANNUAL_UPGRADE_ACCOUNT_DEAL_PATH,
  GetAnnualUpgradeAccountDealResponse
} from '@elfsight-universe/service-core-contracts/deals';
import { client } from '@api';
import { useUser } from '@modules/_app';
import { useEvents } from '@modules/_event-bus';

export function useAnnualUpgradeAccountDealQuery(
  subscriptionPid?: string,
  {
    enabled,
    ...options
  }: UseQueryOptions<GetAnnualUpgradeAccountDealResponse> = {}
) {
  const { isAuthenticated } = useUser();
  const canQuery = !!subscriptionPid && isAuthenticated;

  const query = useQuery<GetAnnualUpgradeAccountDealResponse>(
    [GET_ANNUAL_UPGRADE_ACCOUNT_DEAL_PATH, subscriptionPid],
    () =>
      client
        .get(GET_ANNUAL_UPGRADE_ACCOUNT_DEAL_PATH, {
          searchParams: { subscriptionPid: subscriptionPid as string }
        })
        .json(),
    {
      retry: false,
      enabled: (enabled === undefined || enabled) && canQuery,
      ...options
    }
  );

  useEvents(['DealsUpdated'], () => query.refetch());

  return query;
}
