import { Exclude, Expose, Type } from 'class-transformer';
import { BillingError, WidgetError } from '../errors';
import { PlanGrade, PlanType } from './enums';

export const GET_WIDGET_SUBSCRIPTION_INFO_PATH =
  'billing/get-widget-subscription-info';

export class GetWidgetSubscriptionInfoRequest {
  widgetPid: string;
}

@Exclude()
export class GetWidgetSubscriptionInfoResponsePlan {
  @Expose()
  grade?: PlanGrade;

  @Expose()
  type: PlanType;
}

export class GetWidgetSubscriptionInfoResponse {
  @Expose()
  @Type(() => GetWidgetSubscriptionInfoResponsePlan)
  plan: GetWidgetSubscriptionInfoResponsePlan;
}

export type GetWidgetSubscriptionInfoResponseError =
  | WidgetError.WIDGET_DOES_NOT_EXIST
  | BillingError.SUBSCRIPTION_DOES_NOT_EXIST;
