import { formatSeparatorNumber } from '@elfsight-universe/ui-common';
import { CreateFeatureOptions, FeatureResolver } from '../feature-resolver';
import { FeatureTooltip } from '../../components/feature-tooltip';
import { AI_CHATBOT_APP_ALIAS } from '../constants';

export const aiChatbotCharsStorageLimitFeatureResolver: FeatureResolver = ({
  isPackPlan,
  appAlias,
  planPolicyFeatures
}: CreateFeatureOptions) => {
  const shouldDisplay =
    !isPackPlan &&
    appAlias === AI_CHATBOT_APP_ALIAS &&
    planPolicyFeatures?.aiChatbotCharsStorageLimit;

  if (!shouldDisplay) {
    return;
  }

  return {
    text: (
      <FeatureTooltip
        content={
          <>
            The total storage limit for the knowledge base used across all
            chatbots in your account.
          </>
        }
      >
        <>
          {planPolicyFeatures?.aiChatbotCharsStorageLimit &&
            formatSeparatorNumber(
              planPolicyFeatures.aiChatbotCharsStorageLimit
            )}{' '}
          chars storage
        </>
      </FeatureTooltip>
    )
  };
};
