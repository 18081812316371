import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLogAnnouncementViewMutation } from '@api';

const MIN_TIME_IN_VIEW = 1000; // 1 second;

export function useAnnouncementViewLogging(pid: string) {
  const [hasViewLogged, setHasViewLogged] = useState(false);
  const [inViewObservingRef, isInView] = useInView({
    threshold: 0.5,
    skip: hasViewLogged
  });
  const { mutate: logAnnouncementView } = useLogAnnouncementViewMutation();

  useEffect(() => {
    if (hasViewLogged || !isInView) {
      return;
    }

    const timeout = setTimeout(() => {
      setHasViewLogged(true);
      logAnnouncementView({ announcementPid: pid });
    }, MIN_TIME_IN_VIEW);

    return () => {
      clearTimeout(timeout);
    };
  }, [pid, hasViewLogged, isInView]);

  return inViewObservingRef;
}
