import { Exclude, Expose, Type } from 'class-transformer';
import { PaginatedRequest } from '../paginated-request';
import { PaginationResponse } from '../pagination-response';
import { Announcement, AnnouncementTag } from './classes';

export const GET_PUBLISHED_ANNOUNCEMENTS_PATH =
  'announcement/get-published-announcements';

export class GetPublishedAnnouncementsRequest extends PaginatedRequest {
  itemsPerPage = 10;
}

@Exclude()
export class GetPublishedAnnouncementsResponseMeta extends PaginationResponse {
  @Expose()
  numberOfUnreadAnnouncements: number;

  @Expose()
  announcementsVisited: boolean;

  @Expose()
  currentPage: number;
}

@Exclude()
export class GetPublishedAnnouncementsResponseTag {
  @Expose()
  @Type(() => AnnouncementTag)
  announcementTag: AnnouncementTag;
}

@Exclude()
export class GetPublishedAnnouncementsResponseModel extends Announcement {
  @Expose()
  @Type(() => GetPublishedAnnouncementsResponseTag)
  tags: GetPublishedAnnouncementsResponseTag[];
}

@Exclude()
export class GetPublishedAnnouncementsResponse {
  @Expose()
  @Type(() => GetPublishedAnnouncementsResponseMeta)
  meta: GetPublishedAnnouncementsResponseMeta;

  @Expose()
  @Type(() => GetPublishedAnnouncementsResponseModel)
  payload: GetPublishedAnnouncementsResponseModel[];
}
