import { Exclude, Expose, Type } from 'class-transformer';

export const GET_HIGHEST_PLAN_PATH = 'billing/get-highest-plan';

@Exclude()
export class GetHighestPlanResponse {
  @Expose()
  planName: string;

  @Expose()
  @Type(() => GetHighestPlanResponseApp)
  app?: {
    alias: string;
    name: string;
  };
}

@Exclude()
export class GetHighestPlanResponseApp {
  @Expose()
  alias: string;

  @Expose()
  name: string;
}
