import { createGlobalStyle, css } from 'styled-components';
import { MOBILE } from '@elfsight-universe/ui-common';

const FAB_HEIGHT = 60;
const FAB_OFFSET = 20;
const FAB_MOBILE_OFFSET = 8;

const CONTAINER_OFFSET = FAB_OFFSET + FAB_HEIGHT + 12;
const CONTAINER_MOBILE_OFFSET = FAB_MOBILE_OFFSET + FAB_HEIGHT + 12;
export const BEACON_CLOSE_BUTTON_CONTAINER_ID = 'BeaconCloseButtonContainer';

export const BeaconStyleOverride = createGlobalStyle<{
  _position: 'right' | 'left';
}>`
  .Beacon {
    .BeaconContainer {
      bottom: ${CONTAINER_OFFSET}px !important;
      ${({ _position }) =>
        ({
          right: css`
            right: 20px !important;
          `,
          left: css`
            right: 20px !important;
          `
        })[_position]}
      z-index: ${({ theme }) => theme.zIndex.beacon} !important;

      ${MOBILE} {
        bottom: ${CONTAINER_MOBILE_OFFSET}px !important;
        ${({ _position }) =>
          ({
            right: css`
              right: 8px !important;
            `,
            left: css`
              right: 8px !important;
            `
          })[_position]}
      }

      &-enter-done,
      &-enter-active,
      &-exit-active {
        +.BeaconFabButtonFrame {
          z-index: ${({ theme }) => theme.zIndex.beacon - 1} !important;
        }
      }
    }
    .BeaconFabButtonFrame {
      bottom: ${FAB_OFFSET}px !important;
      ${({ _position }) =>
        ({
          right: css`
            right: 20px !important;
          `,
          left: css`
            right: 20px !important;
          `
        })[_position]}
      ${MOBILE} {
        bottom: ${FAB_MOBILE_OFFSET}px !important;
        ${({ _position }) =>
          ({
            right: css`
              right: 8px !important;
            `,
            left: css`
              right: 8px !important;
            `
          })[_position]}
        }
    }
    .BeaconFabButtonFrame:hover #${BEACON_CLOSE_BUTTON_CONTAINER_ID} {
      visibility: visible;
      opacity: 1;
    }
    .BeaconNotificationsFrame {
      z-index: ${({ theme }) => theme.zIndex.beaconNotification} !important;
      bottom: ${CONTAINER_OFFSET - 10}px !important;
      ${({ _position }) =>
        ({
          right: css`
            right: 10px !important;
          `,
          left: css`
            right: 10px !important;
          `
        })[_position]}
      ${MOBILE} {
        bottom: ${CONTAINER_MOBILE_OFFSET - 10}px !important;
      }
    }
    #BeaconFullScreenFrame {
      z-index: ${({ theme }) => theme.zIndex.beacon + 1}  !important;
    }
  }
`;
