import { useRouter } from 'next/router';

const validParams = [true, false, 1, 0] as const;
type ValidParams = (typeof validParams)[number];

export function useBooleanSearchParam(
  queryParam: string
): [
  boolean | undefined,
  (nextQueryParamValue: ValidParams | undefined) => Promise<boolean>
] {
  const { query, replace, pathname } = useRouter();

  let value: boolean | undefined = undefined;

  if (['1', 'true'].includes(query[queryParam] as string)) {
    value = true;
  }

  if (['0', 'false'].includes(query[queryParam] as string)) {
    value = false;
  }

  const replaceQueryParam = async (
    nextQueryParamValue: ValidParams | undefined
  ) => {
    const newQuery = { ...query };

    if (nextQueryParamValue === undefined) {
      delete newQuery[queryParam];
    }

    const isValidValue = validParams.some((val) => val === nextQueryParamValue);

    if (isValidValue) {
      newQuery[queryParam] = String(nextQueryParamValue);
    }

    return replace({ pathname, query: newQuery });
  };

  return [value, replaceQueryParam];
}
