import { Exclude, Expose, Type } from 'class-transformer';
import { OfferType } from './enums';
import { CustomDataSwitchPlanParams } from './classes';

export const GET_FEATURED_OFFER_PATH = 'deals/get-featured-offer';

@Exclude()
export class GetFeaturedOfferResponseDeal {
  @Expose()
  couponCode?: string;

  @Expose()
  expiredAt?: Date;

  @Expose()
  @Type(() => CustomDataSwitchPlanParams)
  switchPlanParams?: CustomDataSwitchPlanParams;
}

@Exclude()
export class GetFeaturedOfferResponse {
  @Expose()
  type: OfferType;

  @Expose()
  title: string;

  @Expose()
  description: string;

  @Expose()
  @Type(() => GetFeaturedOfferResponseDeal)
  deal: GetFeaturedOfferResponseDeal;

  @Expose()
  isButtonOffer?: boolean;
}
