import styled from 'styled-components';

export const AlertDot = styled.span<{ $top?: number; $right?: number }>`
  position: absolute;
  top: ${({ $top = 0 }) => $top}px;
  right: ${({ $right = 0 }) => $right}px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.alert};
`;
