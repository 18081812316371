import { AccountDeal } from '@elfsight-universe/service-core-contracts/deals';
import {
  ANNUAL_UPGRADE_AFTER_PAYMENT_DEAL_ALIAS,
  ANNUAL_UPGRADE_BEFORE_PAYMENT_DEAL_ALIAS,
  BIRTHDAY_DEAL_ALIAS,
  BLACK_FRIDAY_DEAL_ALIAS,
  REFER_A_FRIEND_DEAL_ALIAS,
  SWITCH_TO_ANNUAL_DEAL_ALIAS,
  SWITCH_TO_PACK_DEAL_ALIAS,
  WELCOME_DEAL_ALIAS,
  XMAS_DEAL_ALIAS
} from '@modules/deals/utils/deal-aliases';
import { PROMO_PAGE_URL } from '@modules/pack-plans-promo/constants';
import { createUpgradeURL } from '@modules/upgrade';

export function getDealURL({ deal, customData }: AccountDeal) {
  const standardURL = `/deals/${deal.alias}`;
  let annualUpgradeURL = standardURL;
  const isSwitchPlanURL = [
    SWITCH_TO_ANNUAL_DEAL_ALIAS,
    ANNUAL_UPGRADE_BEFORE_PAYMENT_DEAL_ALIAS,
    ANNUAL_UPGRADE_AFTER_PAYMENT_DEAL_ALIAS
  ].includes(deal.alias);

  if (isSwitchPlanURL) {
    const annualUpgradeURLParams = new URLSearchParams({
      to: customData?.switchPlanParams?.to as string,
      from: customData?.switchPlanParams?.from as string
    });
    annualUpgradeURL = `/upgrade-confirm?${annualUpgradeURLParams}`;
  }

  const dealURLMapping: Record<string, string> = {
    [REFER_A_FRIEND_DEAL_ALIAS]: '/refer-a-friend',
    [SWITCH_TO_PACK_DEAL_ALIAS]: PROMO_PAGE_URL,
    [SWITCH_TO_ANNUAL_DEAL_ALIAS]: annualUpgradeURL,
    [BIRTHDAY_DEAL_ALIAS]: '/birthday-sale',
    [BLACK_FRIDAY_DEAL_ALIAS]: '/black-friday-sale',
    [XMAS_DEAL_ALIAS]: '/christmas-sale',
    [WELCOME_DEAL_ALIAS]: customData?.lastUsedInstalledAppAlias
      ? createUpgradeURL({ app: customData.lastUsedInstalledAppAlias })
      : PROMO_PAGE_URL,
    [ANNUAL_UPGRADE_BEFORE_PAYMENT_DEAL_ALIAS]: annualUpgradeURL,
    [ANNUAL_UPGRADE_AFTER_PAYMENT_DEAL_ALIAS]: annualUpgradeURL
  };

  return dealURLMapping[deal.alias] || standardURL;
}
