import styled from 'styled-components';
import {
  AlertDot,
  Badge,
  MenuButton,
  MenuButtonProps
} from '@elfsight-universe/ui-common';
import { formatBadgeNumber } from '@modules/_header/utils';
import { useHeader } from './header-provider';

export type HeaderMobileNavItemProps = MenuButtonProps & {
  badge?: number;
  href?: string;
  alert?: boolean;
};

export function HeaderMobileNavItem({
  children,
  badge,
  alert,
  href,
  onClick,
  ...forwardingProps
}: HeaderMobileNavItemProps) {
  const { toggleMobileNav } = useHeader();
  const { activePage } = useHeader();
  const active = href === activePage;
  const badgeText = badge ? formatBadgeNumber(badge) : null;

  return (
    <MenuButton
      {...forwardingProps}
      href={href}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
          return;
        }
        toggleMobileNav(false);
        return true;
      }}
      textColor={active ? 'black' : 'black'}
      interactTextColor="black"
      active={false}
      withArrowIcon
      iconColor="gray50"
      large
    >
      <Content>
        {children}
        {badgeText ? <Badge static>{badgeText}</Badge> : null}
        {alert && <AlertDot $top={0} $right={-6} />}
      </Content>
    </MenuButton>
  );
}

const Content = styled.span`
  position: relative;
`;
