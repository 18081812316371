import { Exclude, Expose, Type } from 'class-transformer';
import { PaginatedRequest } from '../paginated-request';
import { PaginationResponse } from '../pagination-response';
import { Announcement, AnnouncementTag } from './classes';
import { GetAnnouncementDetailResponseTag } from './get-announcement-detail';

export const GET_ANNOUNCEMENTS_LIST_PATH =
  'announcement/get-announcements-list';

export class GetAnnouncementsListRequest extends PaginatedRequest {
  itemsPerPage = 25;
}

@Exclude()
export class GetAnnouncementsListResponse {
  @Expose()
  @Type(() => PaginationResponse)
  meta: PaginationResponse;

  @Expose()
  @Type(() => GetAnnouncementsListResponseModel)
  payload: GetAnnouncementsListResponseModel[];
}

@Exclude()
export class GetAnnouncementsListResponseModel extends Announcement {
  @Expose()
  id: number;

  @Expose()
  updatedAt: Date;

  @Expose()
  published: boolean;

  @Expose()
  @Type(() => GetAnnouncementDetailResponseTag)
  tags: GetAnnouncementDetailResponseTag[];

  @Expose()
  numberOfUniqueViews: number;

  @Expose()
  numberOfUniqueClicks: number;
}

@Exclude()
export class GetAnnouncementsListResponseAnnouncementTag extends AnnouncementTag {
  @Expose()
  id: number;
}

@Exclude()
export class GetAnnouncementsListResponseTag {
  @Expose()
  @Type(() => GetAnnouncementsListResponseAnnouncementTag)
  announcementTag: GetAnnouncementsListResponseAnnouncementTag;
}
