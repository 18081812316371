import { IsUUID } from 'class-validator';
import { BillingError } from '../errors';

export const EXPORT_PDF_INVOICE_PATH = 'billing/export-pdf-invoice';

export class ExportPDFInvoiceRequest {
  @IsUUID()
  paymentPid: string;
}

export type ExportPDFInvoiceResponseError =
  | BillingError.PAYMENT_DOES_NOT_EXIST
  | BillingError.PDF_INVOICE_EXPORT_FAILED;
