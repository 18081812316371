import flatten from 'lodash/flatten';
import {
  CreateFeatureOptions,
  Feature,
  FeatureResolver
} from '@modules/upgrade/pricing-table/pricing-table-features/feature-resolver';
import { PricingTableColorTheme } from '@modules/upgrade/pricing-table';
import {
  aiChatbotBasicFeatureResolvers,
  basicFeatureResolvers
} from '@modules/upgrade/pricing-table/pricing-table-features/basic-feature-resolvers';
import {
  aiChatbotEnterpriseFeatureResolvers,
  enterpriseFeatureResolvers
} from '@modules/upgrade/pricing-table/pricing-table-features/enterprise-feature-resolvers';
import { AI_CHATBOT_APP_ALIAS } from '@modules/upgrade/pricing-table/pricing-table-features/constants';

export function createPricingTableFeatures(
  options: CreateFeatureOptions,
  {
    pricingTableColorTheme = PricingTableColorTheme.WHITE,
    countPublicApps
  }: {
    pricingTableColorTheme?: PricingTableColorTheme;
    countPublicApps?: number;
  }
) {
  let featureResolvers: FeatureResolver[];

  if (options.appAlias === AI_CHATBOT_APP_ALIAS) {
    featureResolvers = options.isEnterprisePlan
      ? aiChatbotEnterpriseFeatureResolvers
      : aiChatbotBasicFeatureResolvers;
  } else {
    featureResolvers = options.isEnterprisePlan
      ? enterpriseFeatureResolvers
      : basicFeatureResolvers;
  }

  const features = featureResolvers
    .map((resolver) =>
      resolver(options, {
        pricingTableColorTheme,
        countPublicApps
      })
    )
    .filter((feature): feature is Feature | Feature[] => Boolean(feature));

  return flatten(features);
}
