import {
  PropsWithChildren,
  useContext,
  createContext,
  useState,
  useEffect,
  useMemo
} from 'react';
import { NextPage } from 'next';
import { BEACON_ID } from '@constants';

export type NextPageWithConfig<P = Record<string, unknown>> = NextPage<P> & {
  config?: Partial<PageState>;
};

export type PageState = {
  guest: boolean;
  offerHidden: boolean;
  beaconID: string;
  beaconHidden: boolean | undefined;
  beaconPosition: 'left' | 'right';
  offerSlideInOpenByDefault: boolean;
  offerModalHidden: boolean;
  onboardingModalHidden: boolean;
  isWidgetConfigurationPage: boolean;
};

export const DEFAULT_PAGE_STATE: PageState = {
  guest: false,
  offerHidden: false,
  beaconID: BEACON_ID,
  beaconHidden: false,
  beaconPosition: 'right',
  offerSlideInOpenByDefault: false,
  offerModalHidden: false,
  onboardingModalHidden: false,
  isWidgetConfigurationPage: false
};

export type IPageContext = PageState & {
  override: (value: Partial<PageState>) => void;
};

export const PageContext = createContext<IPageContext | null>(null);

type PageProviderProps = PropsWithChildren<{
  value?: Partial<PageState>;
}>;

export function AppPageProvider({
  children,
  value = DEFAULT_PAGE_STATE
}: PageProviderProps) {
  const [state, setState] = useState({ ...DEFAULT_PAGE_STATE, ...value });

  useEffect(() => {
    setState({ ...DEFAULT_PAGE_STATE, ...value });
  }, [value]);

  const override = (value: Partial<PageState>) => {
    setState({ ...state, ...value });
  };

  const contextValue = useMemo(
    () => ({ ...state, override }),
    [state, override] // @TODO state is a object, so deps will not work?
  );

  return (
    <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>
  );
}

export function usePageContext() {
  const context = useContext(PageContext);

  if (context === null) {
    throw new Error(
      '`usePageContext` must be nested inside an `AppPageProvider`.'
    );
  }

  return context;
}
