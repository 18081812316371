import styled from 'styled-components';
import { CloseBold } from '@elfsight/icons';
import { Icon, MOBILE, getColorWithAlpha } from '@elfsight-universe/ui-common';
import { usePageContext } from '@modules/_app';

export interface BeaconCloseButtonProps {
  id: string;
}

export function BeaconCloseButton({ id }: BeaconCloseButtonProps) {
  const { override } = usePageContext();

  return (
    <CloseButton
      id={id}
      onClick={() => {
        override({
          beaconHidden: true
        });
      }}
    >
      <CloseButtonIcon component={CloseBold} size={10} />
    </CloseButton>
  );
}

export const CloseButtonIcon = styled(Icon)`
  width: 10px;
  height: 10px
`;

export const CloseButton = styled.div`
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  z-index: 100000;
  opacity: 0;
  right: -3px;
  bottom: 43px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  box-shadow: 0 0 4px ${({ theme }) => getColorWithAlpha(theme.colors.black, 0.15)};
  background-color: #404040;
  transform: scale(0.875);

  :hover {
    background: #292929;
  }

  ${CloseButtonIcon} {
    fill: ${({ theme }) => getColorWithAlpha(theme.colors.white, 0.5)}
  }

  :hover ${CloseButtonIcon} {
    fill: ${({ theme }) => getColorWithAlpha(theme.colors.white, 0.7)};
  }

  ${MOBILE} {
    right: -4px;
    bottom: 43px;
  }
`;
