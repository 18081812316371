import { FeatureResolver } from '@modules/upgrade/pricing-table/pricing-table-features/feature-resolver';
import {
  adFeatureResolver,
  appsCountFeatureResolver,
  installationServiceFeatureResolver,
  supportFeatureResolver,
  viewsLimitFeatureResolver,
  websitesLimitFeatureResolver,
  widgetsLimitFeatureResolver,
  projectsLimitFeatureResolver,
  collaboratorsLimitFeatureResolver,
  projectsSharingFeatureResolver,
  aiReviewsTranslationFeatureResolver,
  advancedCustomizationFeatureResolver,
  aiPostGenerationFeatureResolver,
  aiChatbotMessagesLimitFeatureResolver,
  aiChatbotCharsStorageLimitFeatureResolver
} from '@modules/upgrade/pricing-table/pricing-table-features/feature-resolvers';

export const basicFeatureResolvers: FeatureResolver[] = [
  appsCountFeatureResolver,
  websitesLimitFeatureResolver,
  viewsLimitFeatureResolver,
  widgetsLimitFeatureResolver,
  projectsLimitFeatureResolver,
  collaboratorsLimitFeatureResolver,
  projectsSharingFeatureResolver,
  aiReviewsTranslationFeatureResolver,
  aiPostGenerationFeatureResolver,
  advancedCustomizationFeatureResolver,
  installationServiceFeatureResolver,
  supportFeatureResolver,
  adFeatureResolver
];

export const aiChatbotBasicFeatureResolvers: FeatureResolver[] = [
  websitesLimitFeatureResolver,
  widgetsLimitFeatureResolver,
  aiChatbotMessagesLimitFeatureResolver,
  viewsLimitFeatureResolver,
  aiChatbotCharsStorageLimitFeatureResolver,
  projectsLimitFeatureResolver,
  collaboratorsLimitFeatureResolver,
  projectsSharingFeatureResolver,
  aiReviewsTranslationFeatureResolver,
  aiPostGenerationFeatureResolver,
  advancedCustomizationFeatureResolver,
  installationServiceFeatureResolver,
  supportFeatureResolver,
  adFeatureResolver
];
