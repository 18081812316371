import { useMutation } from '@tanstack/react-query';
import {
  LOG_ANNOUNCEMENT_CLICK_PATH,
  LogAnnouncementClickRequest
} from '@elfsight-universe/service-core-contracts/announcement/log-announcement-click';
import { client } from '../client';

export function useLogAnnouncementClickMutation() {
  return useMutation<Response, undefined, LogAnnouncementClickRequest>((data) =>
    client.post(LOG_ANNOUNCEMENT_CLICK_PATH, {
      json: data
    })
  );
}
