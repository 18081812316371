import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class PlanPolicyFeatures {
  @Expose()
  disableShareByLink?: boolean;

  @Expose()
  aiChatbotMessagesLimit?: number;

  @Expose()
  aiChatbotCharsStorageLimit?: number;

  @Expose()
  reviewsCacheTTL?: number;
}

@Exclude()
export class PlanPolicy {
  @Expose()
  viewsLimit: number | null;

  @Expose()
  widgetsLimit: number;

  @Expose()
  projectsLimit: number;

  @Expose()
  collaboratorsLimit: number;

  @Expose()
  features?: PlanPolicyFeatures;
}
