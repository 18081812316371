import { MouseEventHandler, useState } from 'react';
import styled from 'styled-components';
import { Play } from '@elfsight/icons';
import { Loader } from '@elfsight-universe/ui-common';
import { getVideoID } from '../utils/get-video-id';

interface VideoPreviewProps {
  videoUrl: string;
  iconSize?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onContextMenu?: MouseEventHandler<HTMLDivElement>;
  containerWidth?: string;
}

export const VideoPreview = ({
  videoUrl = '',
  iconSize = 48,
  onClick,
  onContextMenu,
  containerWidth,
  ...forwardingProps
}: VideoPreviewProps) => {
  const videoId = getVideoID(videoUrl);
  const [isThumbnailFetched, setThumbnailFetched] = useState(false);

  const youtubeThumbnails = {
    small: `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`,
    large: `https://img.youtube.com/vi/${videoId}/sddefault.jpg`,
    small2x: `https://img.youtube.com/vi/${videoId}/sddefault.jpg`,
    large2x: `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
  };

  if (!videoId) return null;

  return (
    <Container
      {...forwardingProps}
      onClick={onClick}
      onContextMenu={onContextMenu}
    >
      <StyledPlayIcon _size={iconSize} />

      <StyledImage
        style={{
          display: isThumbnailFetched ? 'block' : 'none'
        }}
        src={youtubeThumbnails.small}
        srcSet={`${youtubeThumbnails.small2x} 2x`}
        alt=""
        onLoad={() => setThumbnailFetched(true)}
      />

      {!isThumbnailFetched && <StyledLoader large />}
    </Container>
  );
};

const StyledImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
  border-radius: 12px;
`;

const Container = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  cursor: pointer;
  padding: 6px 0;
  aspect-ratio: 16 / 9;
`;

const StyledPlayIcon = styled(Play)<{ _size: number }>`
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.25));
  width: ${({ _size }) => _size}px;
  fill: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledLoader = styled(Loader)`
  height: 100%;
`;
