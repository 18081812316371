import { FeatureResolver } from '@modules/upgrade/pricing-table/pricing-table-features/feature-resolver';
import {
  adFeatureResolver,
  appsCountFeatureResolver,
  supportFeatureResolver,
  viewsLimitFeatureResolver,
  websitesLimitFeatureResolver,
  widgetsLimitFeatureResolver,
  additionalFeaturesFeatureResolver,
  installationServiceFeatureResolver,
  projectsLimitFeatureResolver,
  collaboratorsLimitFeatureResolver,
  projectsSharingFeatureResolver,
  aiReviewsTranslationFeatureResolver,
  advancedCustomizationFeatureResolver,
  aiPostGenerationFeatureResolver,
  aiChatbotMessagesLimitFeatureResolver,
  aiChatbotCharsStorageLimitFeatureResolver
} from './feature-resolvers';

export const enterpriseFeatureResolvers: FeatureResolver[] = [
  appsCountFeatureResolver,
  websitesLimitFeatureResolver,
  viewsLimitFeatureResolver,
  widgetsLimitFeatureResolver,
  projectsLimitFeatureResolver,
  collaboratorsLimitFeatureResolver,
  projectsSharingFeatureResolver,
  aiReviewsTranslationFeatureResolver,
  aiPostGenerationFeatureResolver,
  advancedCustomizationFeatureResolver,
  installationServiceFeatureResolver,
  supportFeatureResolver,
  adFeatureResolver,
  additionalFeaturesFeatureResolver
];

export const aiChatbotEnterpriseFeatureResolvers: FeatureResolver[] = [
  websitesLimitFeatureResolver,
  widgetsLimitFeatureResolver,
  aiChatbotMessagesLimitFeatureResolver,
  viewsLimitFeatureResolver,
  aiChatbotCharsStorageLimitFeatureResolver,
  projectsLimitFeatureResolver,
  collaboratorsLimitFeatureResolver,
  projectsSharingFeatureResolver,
  aiReviewsTranslationFeatureResolver,
  aiPostGenerationFeatureResolver,
  advancedCustomizationFeatureResolver,
  installationServiceFeatureResolver,
  supportFeatureResolver,
  adFeatureResolver,
  additionalFeaturesFeatureResolver
];
