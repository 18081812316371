export const WELCOME_DEAL_ALIAS = 'welcome';
export const SECOND_APP_DEAL_ALIAS = 'second-app';
export const SWITCH_TO_PACK_DEAL_ALIAS = 'switch-to-pack';
export const SWITCH_TO_ANNUAL_DEAL_ALIAS = 'switch-to-annual';
export const REFER_A_FRIEND_DEAL_ALIAS = 'refer-a-friend';
export const BLACK_FRIDAY_DEAL_ALIAS = 'black-friday';
export const XMAS_DEAL_ALIAS = 'xmas';
export const BIRTHDAY_DEAL_ALIAS = 'birthday';
export const ANNUAL_UPGRADE_BEFORE_PAYMENT_DEAL_ALIAS =
  'annual-upgrade-before-payment';
export const ANNUAL_UPGRADE_AFTER_PAYMENT_DEAL_ALIAS =
  'annual-upgrade-after-payment';
