import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  EXPORT_PDF_INVOICE_PATH,
  ExportPDFInvoiceRequest,
  ExportPDFInvoiceResponseError
} from '@elfsight-universe/service-core-contracts/billing';
import { client } from '../client';

export function useExportPDFInvoiceMutation(
  options: Pick<
    UseMutationOptions<
      undefined,
      ExportPDFInvoiceResponseError,
      ExportPDFInvoiceRequest
    >,
    'onError' | 'onSuccess'
  >
) {
  return useMutation<
    undefined,
    ExportPDFInvoiceResponseError,
    ExportPDFInvoiceRequest
  >(
    async (data) => {
      const response = await client.get(EXPORT_PDF_INVOICE_PATH, {
        searchParams: { ...data }
      });

      const buffer = await response.arrayBuffer();
      const contentDisposition = response.headers.get('Content-Disposition');

      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      const downloadURL = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = getFilename(contentDisposition || '');
      link.style.display = 'none';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(downloadURL);
    },
    {
      retry: false,
      ...options
    }
  );
}

const getFilename = (contentDisposition: string) => {
  let filename = 'receipt.pdf';

  if (contentDisposition && contentDisposition.includes('attachment')) {
    const matches = contentDisposition.match(/filename="([^"]+)"/);
    if (matches && matches[1]) {
      filename = matches[1];
    }
  }

  return filename;
};
