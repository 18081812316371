import { ParsedUrlQuery } from 'querystring';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useRouter } from 'next/router';
import ms from 'ms';
import { GetUpgradeContextResponse } from '@elfsight-universe/service-core-contracts/billing/get-upgrade-context';
import { SelectPlanAction } from '@elfsight-universe/service-core-contracts/billing/enums/select-plan-action.enum';
import { BillingInterval } from '@elfsight-universe/service-core-contracts/billing';
import { WidgetError } from '@elfsight-universe/service-core-contracts/errors';
import {
  useAnnualUpgradeAccountDealQuery,
  useCountPublicAppsQuery,
  useDealByAliasQuery,
  usePricingIncludedAppsQuery,
  useUpgradeContextQuery
} from '@api';
import { useOffer } from '@modules/offer';
import { useEnterprisePlans } from '@modules/upgrade/utils/use-enterprise-plans';
import { catchUnhandledError, ErrorPage } from '@modules/_error';
import { WELCOME_DEAL_ALIAS } from '@modules/deals/utils/deal-aliases';
import { useInterval } from '@modules/upgrade/utils';
import {
  APP_ALIAS_SLUG,
  BILLING_INTERVAL_PARAM,
  CLAIM_PARAM,
  CTAClaim,
  SUBSCRIPTION_PID
} from './constants';
import { UpgradeAllAppsPackModal } from './upgrade-all-apps-pack-modal';

const OFFER_SLIDE_IN_TIMEOUT = 2000;

export type IUpgradeContext = {
  claim?: CTAClaim;
  billingInterval: BillingInterval;
  setBillingInterval: (value: BillingInterval) => void;
  isEnterprise: boolean;
  setEnterprise: (value: boolean) => void;
  isLoading: boolean;
  data?: GetUpgradeContextResponse;
  isReadyUpgradeContext: boolean;
  isOpenAllAppsPackModal: boolean;
  setOpenAllAppsPackModal: (value: boolean) => void;
  isWelcomeDeal: boolean;
  isAnnualUpgradeDeal: boolean;
  preferablyCreateNewSubscription?: boolean;
};

export const UpgradeContext = createContext<IUpgradeContext | null>(null);

export interface UpgradeProviderProps {
  children?: ReactNode;
  preferablyCreateNewSubscription?: boolean;
}

export function UpgradeProvider({
  children,
  preferablyCreateNewSubscription = false
}: UpgradeProviderProps) {
  const { query, isReady } = useRouter();
  const appAlias = query[APP_ALIAS_SLUG] as string | undefined;
  const subscriptionPid = query[SUBSCRIPTION_PID] as string | undefined;

  const [isReadyUpgradeContext, setReadyUpgradeContext] = useState(false);
  const [isOpenAllAppsPackModal, setOpenAllAppsPackModal] = useState(false);

  const { slideInOpen, toggleSlideInOpen } = useOffer();
  const [billingInterval, setBillingInterval] = useState<BillingInterval>();
  const [isEnterprise, setEnterprise] = useEnterprisePlans();

  const requestedBillingInterval = getBillingIntervalFromQuery(query);

  const { isError, error, data, isLoading, isFetchedAfterMount } =
    useUpgradeContextQuery(
      {
        appAlias,
        requestedBillingInterval,
        preferablyCreateNewSubscription,
        subscriptionPid
      },
      {
        enabled: isReady,
        onError: (error) => {
          catchUnhandledError(error, [WidgetError.APP_DOES_NOT_EXIST]);
        }
      }
    );

  const [isWelcomeDeal, setWelcomeDeal] = useState(false);
  const { data: welcomeDealData } = useDealByAliasQuery({
    alias: WELCOME_DEAL_ALIAS
  });
  const [isAnnualUpgradeDeal, setAnnualUpgradeDeal] = useState(false);
  const { data: annualUpgradeDealData } = useAnnualUpgradeAccountDealQuery(
    data?.effectiveSubscriptionPid
  );

  const checkDeal = useCallback(() => {
    const currentDate = new Date();

    const isWelcomeDealActive =
      !!welcomeDealData?.accountDeal &&
      !welcomeDealData.accountDeal.usedAt &&
      !!welcomeDealData.accountDeal.expiredAt &&
      currentDate < new Date(welcomeDealData.accountDeal.expiredAt) &&
      data?.selectPlanAction === SelectPlanAction.SUBSCRIBE_VIA_PADDLE;
    setWelcomeDeal(isWelcomeDealActive);

    const isAnnualUpgradeDealActive =
      !!annualUpgradeDealData?.accountDeal &&
      !!annualUpgradeDealData.accountDeal.expiredAt &&
      currentDate < new Date(annualUpgradeDealData.accountDeal.expiredAt) &&
      data?.selectPlanAction === SelectPlanAction.UPGRADE_VIA_PADDLE;
    setAnnualUpgradeDeal(isAnnualUpgradeDealActive);
  }, [data, welcomeDealData, annualUpgradeDealData]);

  const intervalCheckActive =
    !!welcomeDealData?.accountDeal || annualUpgradeDealData?.accountDeal;
  useInterval(checkDeal, intervalCheckActive ? ms('1 minute') : null);

  useEffect(() => {
    checkDeal();
  }, [data, welcomeDealData, annualUpgradeDealData]);

  useEffect(() => {
    if (data?.defaultBillingInterval) {
      setBillingInterval(data.defaultBillingInterval);
    }
  }, [data?.defaultBillingInterval]);

  useEffect(() => {
    if (!slideInOpen && !isFetchedAfterMount) {
      toggleSlideInOpen(true, OFFER_SLIDE_IN_TIMEOUT);
    }
  }, [slideInOpen, isFetchedAfterMount]);

  useEffect(() => {
    if (data?.showEnterprisePricing) {
      setEnterprise(data.showEnterprisePricing);
    }
  }, [data?.showEnterprisePricing]);

  useEffect(() => {
    if (!isReadyUpgradeContext) {
      setReadyUpgradeContext(true);
    }
  }, [isReadyUpgradeContext]);

  /**
   * preload data for PricingTableAllAppsPackList
   * can be safely removed
   */
  usePricingIncludedAppsQuery();
  useCountPublicAppsQuery();

  if (isError && error === WidgetError.APP_DOES_NOT_EXIST) {
    return <ErrorPage statusCode={404} />;
  }

  return (
    <UpgradeContext.Provider
      value={{
        billingInterval: billingInterval || BillingInterval.YEARLY,
        setBillingInterval,
        isEnterprise,
        setEnterprise,
        claim: {
          removeBranding: CTAClaim.REMOVE_BRANDING,
          upgradePlan: CTAClaim.UPGRADE_PLAN
        }[query[CLAIM_PARAM] as string],
        data,
        isLoading,
        isReadyUpgradeContext,
        isOpenAllAppsPackModal,
        setOpenAllAppsPackModal,
        isWelcomeDeal,
        isAnnualUpgradeDeal,
        preferablyCreateNewSubscription
      }}
    >
      {children}

      <UpgradeAllAppsPackModal
        isOpen={isOpenAllAppsPackModal}
        onRequestClose={() => setOpenAllAppsPackModal(false)}
      />
    </UpgradeContext.Provider>
  );
}

const getBillingIntervalFromQuery = (query: ParsedUrlQuery) => {
  const queryBillingInterval = query[BILLING_INTERVAL_PARAM] as
    | string
    | undefined;
  return queryBillingInterval?.toUpperCase() as BillingInterval | undefined;
};

export function useUpgradeContext() {
  const context = useContext(UpgradeContext);

  if (context === null) {
    throw new Error(
      '`useUpgradeContext` must be nested inside an `UpgradeProvider`.'
    );
  }

  return context;
}
