import { Fragment, useState } from 'react';
import styled, { css } from 'styled-components';
import Image from 'next/image';
import { Link } from '@elfsight-universe/ui-common';
import { useAnnouncementClickLogging } from '../utils';

export type AnnouncementImageProps = {
  announcementPid: string;
  src: string;
  link?: string;
};

export function AnnouncementImage({
  announcementPid,
  src,
  link
}: AnnouncementImageProps) {
  const [isThumbnailFetched, setThumbnailFetched] = useState(false);
  const Wrapper = link ? StyledLink : Fragment;

  const logImageAnnouncementClick = useAnnouncementClickLogging(
    announcementPid,
    'image'
  );

  return (
    <Wrapper
      href={link}
      onClick={logImageAnnouncementClick}
      onContextMenu={logImageAnnouncementClick}
    >
      <ImageContainer _withBorder={isThumbnailFetched}>
        <Image
          src={src}
          alt=""
          width={350}
          height={0}
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'contain'
          }}
          quality={90}
          onLoad={() => setThumbnailFetched(true)}
        />
      </ImageContainer>
    </Wrapper>
  );
}

const StyledLink = styled(Link)`
  display: block;
  line-height: 0;
`;

const ImageContainer = styled.div<{ _withBorder?: boolean }>`
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  display: inline-block;
  margin: 5px 0;
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  font-size: 0;
  line-height: 0;

  ${({ _withBorder }) =>
    _withBorder &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.gray10};
  `}
`;
