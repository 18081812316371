import { useState } from 'react';
import { useAnnouncementClickLogging } from '../utils';
import { VideoPreview } from './video-preview';
import { VideoModal } from './video-modal';

export type VideoComponentProps = {
  announcementPid: string;
  videoUrl: string;
  containerWidth?: string;
};

export function VideoPlayerDialog({
  announcementPid,
  videoUrl,
  containerWidth
}: VideoComponentProps) {
  const [isModalOpen, setModalOpen] = useState(false);

  const logVideoAnnouncementClick = useAnnouncementClickLogging(
    announcementPid,
    'video'
  );

  function handlePreviewClick() {
    logVideoAnnouncementClick();
    setModalOpen(true);
  }

  return (
    <>
      <VideoPreview
        videoUrl={videoUrl}
        containerWidth={containerWidth}
        onClick={handlePreviewClick}
        onContextMenu={logVideoAnnouncementClick}
      />

      {isModalOpen && (
        <VideoModal
          url={videoUrl}
          isOpen={isModalOpen}
          onRequestClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
}
