import { ReactNode } from 'react';
import BaseCountdown from 'react-countdown';
import styled from 'styled-components';
import { Sandclock16 } from '@elfsight/icons';
import { FontType, ThemeColor } from '../theme';
import { Icon } from './icons';

export type CountdownProps = {
  expiredAt: Date | string;
  color?: ThemeColor;
  timerMinWidth?: number;
  font?: FontType;
  iconSize?: number;
  fallback?: ReactNode;
};

export function Countdown({
  expiredAt,
  color = 'black',
  font = 'title3Semibold',
  iconSize = 12,
  fallback
}: CountdownProps) {
  const fallbackComponent = fallback ?? (
    <>
      <StyledIcon component={Sandclock16} size={iconSize} />
      <span>Limited Time Deal</span>
    </>
  );

  return (
    <BaseCountdown
      date={expiredAt}
      renderer={({ days, hours, minutes, seconds, completed }) =>
        completed ? (
          <FallbackContainer _font={font} _color={color}>
            {fallbackComponent}
          </FallbackContainer>
        ) : (
          <Container _color={color}>
            {days > 0 && (
              <>
                <Item>
                  <Timer>{to2Digit(days)}</Timer>
                  <Caption>days</Caption>
                </Item>
                <Timer>:</Timer>
              </>
            )}
            <Item>
              <Timer>{to2Digit(hours)}</Timer>
              <Caption>hrs</Caption>
            </Item>
            <Timer>:</Timer>
            <Item>
              <Timer>{to2Digit(minutes)}</Timer>
              <Caption>mins</Caption>
            </Item>
            <Timer>:</Timer>
            <Item>
              <Timer>{to2Digit(seconds)}</Timer>
              <Caption>secs</Caption>
            </Item>
          </Container>
        )
      }
    />
  );
}

const Container = styled.div<{ _color: ThemeColor }>`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ _color, theme }) => theme.colors[_color]};
`;

const FallbackContainer = styled.div<{ _font: FontType; _color: ThemeColor }>`
  display: flex;
  align-items: center;
  color: ${({ _color, theme }) => theme.colors[_color]};

  ${({ theme, _font }) => theme.font[_font]};
`;

const StyledIcon = styled(Icon)`
  margin-right: 4px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Timer = styled.span`
  ${({ theme }) => theme.font.title3Bold};
  margin-top: -4px;
`;

const Caption = styled.span`
  ${({ theme }) => theme.font.captionExtraSmall};
  margin-top: -4px;
`;

const to2Digit = (number: number) =>
  `${number}`.length === 1 ? `0${number}` : number;
