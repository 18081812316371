import { useCallback } from 'react';
import { useLogAnnouncementClickMutation } from '@api';

export function useAnnouncementClickLogging(pid: string, target: string) {
  const { mutate: logAnnouncementClick } = useLogAnnouncementClickMutation();

  return useCallback(() => {
    logAnnouncementClick({
      announcementPid: pid,
      target
    });
  }, [pid, target]);
}
