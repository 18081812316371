import styled from 'styled-components';
import { ToggleSwitch } from '@elfsight-universe/ui-common/src/components/controls';
import { DESKTOP, MOBILE } from '@elfsight-universe/ui-common/src/breakpoints';
import { exactRoundingFloatValue } from '@elfsight-universe/ui-common';
import { BillingInterval } from '@elfsight-universe/service-core-contracts/billing';

export type PricingTablePeriodToggleProps = {
  withAnnualUpgradeDeal: boolean;
  billingInterval: BillingInterval;
  onChange: (period: BillingInterval) => void;
  annualSale?: number;
};

export function PricingTablePeriodToggle({
  withAnnualUpgradeDeal,
  billingInterval,
  onChange,
  annualSale = 0.17
}: PricingTablePeriodToggleProps) {
  const handleChange = () =>
    onChange(
      {
        [BillingInterval.MONTHLY]: BillingInterval.YEARLY,
        [BillingInterval.YEARLY]: BillingInterval.MONTHLY
      }[billingInterval]
    );

  return (
    <Container>
      <Label
        onClick={() => onChange(BillingInterval.MONTHLY)}
        _active={billingInterval === BillingInterval.MONTHLY}
      >
        Monthly
      </Label>

      <ToggleSwitch
        onChange={handleChange}
        checked={billingInterval === BillingInterval.YEARLY}
        uncheckedColor="gray50"
        checkedColor="gray50"
      />

      <Label
        onClick={() => onChange(BillingInterval.YEARLY)}
        _active={billingInterval === BillingInterval.YEARLY}
      >
        Annually{' '}
        {billingInterval === BillingInterval.MONTHLY && (
          <Sale _withAnnualUpgradeDeal={withAnnualUpgradeDeal}>
            (save {exactRoundingFloatValue(annualSale * 100)}%
            {withAnnualUpgradeDeal && ' + 1 free month'})
          </Sale>
        )}
      </Label>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  align-items: center;

  ${DESKTOP} {
    grid-template-columns: 1fr auto 1fr;
    margin-bottom: 32px;
  }
  ${MOBILE} {
    justify-content: center;
    grid-template-columns: auto auto auto;
    margin-bottom: 16px;
  }
`;

const Label = styled.label<{ _active: boolean }>`
  ${({ theme }) => theme.font.title4};
  color: ${({ _active, theme: { colors } }) =>
    _active ? colors.black : colors.gray50};
  cursor: pointer;

  :first-child {
    text-align: right;
  }
  :last-child {
    text-align: left;
  }
`;

const Sale = styled.span<{ _withAnnualUpgradeDeal: boolean }>`
  ${({ theme }) => theme.font.title4};
  color: ${({ theme, _withAnnualUpgradeDeal }) => (_withAnnualUpgradeDeal ? theme.colors.annualUpgrade : theme.colors.brand)};
`;
