import { Exclude, Expose, Type } from 'class-transformer';
import { IsUUID } from 'class-validator';
import { AccountDeal } from './classes';

export const GET_ANNUAL_UPGRADE_ACCOUNT_DEAL_PATH =
  'deals/get-annual-upgrade-account-deal';

export class GetAnnualUpgradeAccountDealRequest {
  @IsUUID()
  subscriptionPid: string;
}

@Exclude()
export class GetAnnualUpgradeAccountDealResponse {
  @Expose()
  @Type(() => AccountDeal)
  accountDeal: AccountDeal | null;
}
