import styled from 'styled-components';
import BaseCountdown from 'react-countdown';

export type SuccessModalCountdownProps = {
  expiredAt: Date | string;
  timerMinWidth?: number;
};

export function SuccessModalCountdown({
  expiredAt,
  timerMinWidth = 7,
  ...forwardingProps
}: SuccessModalCountdownProps) {
  return (
    <BaseCountdown
      date={expiredAt}
      renderer={({ hours, minutes, seconds, completed }) =>
        completed ? null : (
          <Container {...forwardingProps}>
            <Timer role="timer" style={{ minWidth: `${timerMinWidth}ch` }}>
              {to2Digit(hours)}:{to2Digit(minutes)}:{to2Digit(seconds)}
            </Timer>
          </Container>
        )
      }
    />
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.alert};
`;

const Timer = styled.span`
  ${({ theme }) => theme.font.title4Semibold}
`;

const to2Digit = (number: number) =>
  `${number}`.length === 1 ? `0${number}` : number;
