import { useMutation } from '@tanstack/react-query';
import {
  LOG_ANNOUNCEMENT_VIEW_PATH,
  LogAnnouncementViewRequest
} from '@elfsight-universe/service-core-contracts/announcement/log-announcement-view';
import { client } from '../client';

export function useLogAnnouncementViewMutation() {
  return useMutation<Response, undefined, LogAnnouncementViewRequest>((data) =>
    client.post(LOG_ANNOUNCEMENT_VIEW_PATH, {
      json: data
    })
  );
}
